import React, { useState } from 'react'
import Logo from '../../components/Logo/Logo'
import './Success.css'
import { Link, useLocation } from 'react-router-dom'

function Success() {
    let urlParams = useLocation()
    //eslint-disable-next-line  no-unused-vars
    const [files, setFiles] = useState(urlParams.state.filePath)
    const sum = urlParams.state.filePath.reduce((count, file) => {
        return count + file.size
    }, 0)

    return (
        <div className='wolken2 h-[100vh] flex justify-center items-center'>
            <div className='flex flex-col bg-white w-[300px] sm:w-[370px] py-[20px] px-[25px] justify-center gap-[10px] shadow-lg rounded-lg'>
                <Logo />
                <div className='flex flex-col w-[260px]'>
                    <h1 className='font-semibold text-[20px] w-[310px] '>Files have been sent!</h1>
                    <p className='sm:w-[310px] w-[260px] text-[16px]'>
                        You will recieve an email of the information that has been sent to the reciever
                    </p>
                </div>
                <h1 className='font-semibold w-[310px]'>
                    {urlParams.state.filePath.length} Files
                    <span className='text-[#a0a0a0] ml-[5px]'>
                        ({parseFloat((sum * 100) / 100 / 1000000).toFixed(2)}&nbsp;MB){' '}
                    </span>
                </h1>
                <hr className='sm:w-[310px] w-[250px]'></hr>
                {files &&
                    files.map((file) => (
                        <div className='flex flex-row' key={file.name}>
                            <div className='flex flex-row  w-[100%] flex-start justify-between'>
                                <p className='text-[14px] truncate sm:max-w-[200px] max-w-[140px]'>{file.name} </p>
                            </div>
                            <div className='flex-end'>
                                <p className='text-[14px] w-[80px] flex-end'>
                                    {parseFloat((file.size * 100) / 100 / 1000000).toFixed(2)}&nbsp;MB{' '}
                                </p>
                            </div>
                        </div>
                    ))}

                <button className='text-white sm:w-[310px] w-[260px] bg-[#84BE94] rounded-md py-[7px] mt-[10px]'>
                    <Link to='/'>Want to send more files? </Link>
                </button>
            </div>
        </div>
    )
}

export default Success
