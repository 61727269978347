import React from 'react'

function ErrorPage() {
    return (
        <div>
            <h1>Something went wrong</h1>
        </div>
    )
}

export default ErrorPage
