import React, { useState, useEffect } from 'react'
import random from '../../images/random.svg'
import check from '../../images/check.svg'
import copy_regular from '../../images/copy_regular.svg'
import './GenPassword.css'

function GenPassword({ onChange }) {
    const [password, setPassword] = useState('')
    const [isChecked, setIsChecked] = useState(false)
    const [toggleImage, setToggleImage] = useState(true)

    const pwdGenerator = () => {
        var chars = '0123456789abcdefghijklmnopqrstuvwxyz!@#$%^&*()ABCDEFGHIJKLMNOPQRSTUVWXYZ'
        var passwordLength = 12
        var password = ''

        for (var i = 0; i <= passwordLength; i++) {
            var randomNumber = Math.floor(Math.random() * chars.length)
            password += chars.substring(randomNumber, randomNumber + 1)
        }
        setPassword(password)
        setToggleImage(true)
    }

    useEffect(() => {
        onChange(password, isChecked)

        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, isChecked])

    const handlePassword = (e) => {
        setPassword(e.target.value)
        onChange(password)
        setToggleImage(true)
        setIsChecked(false)
    }

    const copyText = () => {
        navigator.clipboard.writeText(password)
        setToggleImage(false)
    }

    return (
        <div className='h-[60] flex flex-wrap w-[260px] sm:w-[320px]'>
            <p className='text-sm font-semibold sm:text-base'>
                Password <span className='text-gray-400'>(optional)</span>
            </p>
            <div className='copyDiv'>
                <input
                    id='password'
                    type='text'
                    value={password}
                    onChange={handlePassword}
                    className='w-[260px] sm:w-[320px] h-[35px] bg-white border-2 border-gray-200 box-border rounded-md mt-[5px] pl-[15px] placeholder:text-[14px]'
                />

                <button onClick={copyText} className='copyButton'>
                    {toggleImage && <img className='copy' src={copy_regular} alt='Copied' />}
                    {!toggleImage && <img className='copy' src={check} alt='Copy' />}
                </button>
            </div>

            <button
                onClick={pwdGenerator}
                id='button'
                className='text-[#4353AF] font-semibold pl-[5px] text-sm mb-[10px] sm:text-base flex flex-row-reverse items-center my-[5px]'
            >
                <p className='text-sm'>Generate password</p>
                <img src={random} alt='' className='w-[16px] sm:w-[20px] mr-[5px]' />
            </button>

            {password && (
                <div className='w-[300px] mb-[10px] flex'>
                    <input
                        type='checkbox'
                        name='check'
                        checked={isChecked}
                        value={isChecked}
                        onClick={() => setIsChecked(!isChecked)}
                        onChange={(e) => setIsChecked(e.target.checked)}
                        className='w-[18px] h-[18px] mr-[10px] ml-[5px]'
                    />
                    <label className='text-sm' htmlFor='check'>
                        I saved my password
                    </label>
                </div>
            )}
        </div>
    )
}

export default GenPassword
