import React from 'react';
import './DeleteState.css';
import warning from '../../images/warning.svg';

function DeleteState ({onCancel, onDelete, fileName}) {
  
  return (
    <div className="left-[15%] top-[25%] absolute w-[260px] h-[200px] bg-[#D44949] rounded-lg flex flex-col justify-center items-center gap-[10px]">
        <div>
            <img src={warning} alt='w'/>
        </div>
        <div>
            <p className='text-[18px] font-semibold text-center text-white drop-shadow-sm'>Delete file?</p>
            <p className='text-[14px] text-center text-white px-[20px]'>Are you sure you want to delete {fileName}?</p>
        </div>
        <div>
            <button className='text-[14px] py-[7px] w-[100px] rounded-md bg-white mr-[5px]' onClick={onCancel}>Cancel</button> 
            <button className='text-[14px] text-white py-[7px] w-[100px] rounded-md bg-[#F66666] ml-[5px]' onClick={onDelete}>Delete</button>
        </div>
    </div>
  );
}

export default DeleteState;


