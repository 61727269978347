import React, { useState, useEffect } from 'react'
import GenPassword from '../GenPassword/GenPassword'
import EmailSend from '../EmailSend/EmailSend'

function NameForm({ formToBox }) {
    const [email, setEmail] = useState('')
    const [invalid, setInvalid] = useState('')
    const [message, setMessage] = useState('')
    const [password, setPassword] = useState('')
    const [toEmails, setToEmails] = useState([])
    const [isChecked, setIsChecked] = useState(false)

    const emailValidation = (e) => {
        //eslint-disable-next-line no-useless-escape
        const regEx = /[a-zA-Z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,8}(.[a-z{2,8}])?/g
        if (!regEx.test(email) && email !== '') {
            setInvalid('Email is Not Valid')
        } else {
            setInvalid('')
        }
    }

    useEffect(() => {
        formToBox({
            password: password,
            email: email,
            toEmails: toEmails,
            message: message,
            check: isChecked
        })
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [password, email, toEmails, message, isChecked])

    return (
        <div className='flex gap-[20px] flex-col items-center'>
            <EmailSend
                onChange={(addresses) => {
                    setToEmails(addresses)
                }}
            />
            <div className='h-[60] flex flex-wrap w-[260px] sm:w-[320px]'>
                <label htmlFor='yourEmail' className='text-sm font-semibold sm:text-base'>
                    Your email
                </label>
                <input
                    type='email'
                    placeholder='your@email.com'
                    onBlur={(e) => emailValidation(e)}
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    name='yourEmail'
                    id='yourEmail'
                    className='w-[260px] sm:w-[320px] h-[35px] bg-white border-2 border-gray-200 box-border rounded-md mt-[5px] pl-[15px] placeholder:text-[16px]'
                />
                <p className=' text-[#D44949]'>{invalid}</p>
            </div>

            <div className='h-[60] flex flex-wrap w-[260px] sm:w-[320px]'>
                <p className='text-sm font-semibold sm:text-base'>Message</p>
                <textarea
                    type='text'
                    placeholder=''
                    className='w-[320px] h-[35px] bg-white border-2 border-gray-200 box-border rounded-md mt-[5px] pl-[15px] pb-[65px]'
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                />
            </div>
            <GenPassword
                onChange={(p, check) => {
                    setPassword(p)
                    setIsChecked(check)
                }}
            />
        </div>
    )
}

export default NameForm
