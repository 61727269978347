import './App.css';
import Boxes from './components/Boxes/Boxes';
import Success from './components/Success/Success'; 
import { BrowserRouter, Routes, Route } from "react-router-dom";
import ErrorPage from './components/ErrorPage/ErrorPage';
import DownloadPage from './components/DownloadPage/DownloadPage';
import React from 'react';

function App() {
  return (
   <BrowserRouter>
     <Routes>
        <Route path='/' element={<Boxes />} />
        <Route path='/success' element={<Success />} />
        <Route path='/downloadpage' element={<DownloadPage />} />
        <Route path='*' element={<ErrorPage />} />
     </Routes>
   </BrowserRouter> 
  );
}

export default App;
