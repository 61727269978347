import React from 'react'
import './Logo.css'
import logouploadswap from '../../images/logo_uploadswap.svg'

class Logo extends React.Component {
    render() {
        return <img className='w-[60%] self-center' src={logouploadswap} alt='Uploadswap' />
    }
}

export default Logo
