import React from 'react';
import Box1 from '../Box1/Box1';
import Box2 from '../Box2/Box2';
import './Boxes.css';

function Boxes () {
    return (
        <div className='Boxes'>
            <div>
                <Box1 />
            </div>
            <div>
                <Box2 />
            </div>
        </div>
        
    );
}

export default Boxes;