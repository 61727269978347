import React from 'react'
import './EmailSend.css'

class EmailSend extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            items: [],
            value: '',
            error: null
        }
    }

    setCurrentValue = () => {
        var value = this.state.value.trim()

        if (value && this.isValid(value)) {
            this.setState(
                {
                    items: [...this.state.items, this.state.value],
                    value: ''
                },
                () => {
                    this.props.onChange(this.state.items)
                }
            )
        }
    }

    handleKeyDown = (evt) => {
        // Enter, Tab, Comma, Space
        if ([13, 9, 188, 32].includes(evt.keyCode)) {
            evt.preventDefault()
            this.setCurrentValue()
        }
    }

    handleBlur = (evt) => {
        evt.preventDefault()
        this.setCurrentValue()
    }

    handleChange = (evt) => {
        const val = evt.target.value
        // On space or comma, check email addresses and set as value if valid
        if (val.includes(',') || val.includes(' ')) {
            evt.preventDefault()
            return this.setCurrentValue()
        }
        this.setState({
            value: evt.target.value,
            error: null
        })
    }

    handleDelete = (item) => {
        this.setState(
            {
                items: this.state.items.filter((i) => i !== item)
            },
            () => {
                this.props.onChange(this.state.items)
            }
        )
    }

    handlePaste = (evt) => {
        evt.preventDefault()

        var paste = evt.clipboardData.getData('text')
        // eslint-disable-next-line
        var emails = paste.match(/[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/g)

        if (emails) {
            var toBeAdded = emails.filter((email) => !this.isInList(email))

            this.setState(
                {
                    items: [...this.state.items, ...toBeAdded]
                },
                () => {
                    this.props.onChange(this.state.items)
                }
            )
        }
    }

    isValid(email) {
        let error = null

        if (this.isInList(email)) {
            error = `${email} has already been added.`
        }

        if (!this.isEmail(email)) {
            error = `Email is Not Valid`
        }

        if (error) {
            this.setState({ error })

            return false
        }

        return true
    }

    isInList(email) {
        return this.state.items.includes(email)
    }

    isEmail(email) {
        // eslint-disable-next-line
        return /[\w\d\.-]+@[\w\d\.-]+\.[\w\d\.-]+/.test(email)
    }

    render() {
        return (
            <div className='h-[60] flex flex-wrap w-[260px] sm:w-[320px]'>
                {this.state.items.map((item) => (
                    <div className='tag-item' key={item}>
                        {item}
                        <button type='button' className='button' onClick={() => this.handleDelete(item)}>
                            &times;
                        </button>
                    </div>
                ))}
                <div>
                    <label htmlFor='test' className='text-sm font-semibold sm:text-base'>
                        Send to
                    </label>
                    <input
                        className={
                            'w-[260px] sm:w-[320px] h-[35px] bg-white border-2 border-gray-200 box-border rounded-md mt-[5px] pl-[15px] ' +
                            (this.state.error && ' has-error')
                        }
                        value={this.state.value}
                        placeholder='email@to.com'
                        onKeyDown={this.handleKeyDown}
                        onBlur={this.handleBlur}
                        onChange={this.handleChange}
                        autoComplete='false'
                        onPaste={this.handlePaste}
                        id='test'
                        name='test'
                    />
                </div>

                {this.state.error && <p className='error'>{this.state.error}</p>}
            </div>
        )
    }
}

export default EmailSend
