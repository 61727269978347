import { React, useState } from 'react';
import angle_right from '../../images/angle_right.svg';
import lock from '../../images/lock.svg';


function Box2() {
    const [isOpen, setIsOpen] = useState(false);
    const [buttonRotate, setButtonRotate] = useState(false);

    const openBox = () => {
      setIsOpen(!isOpen)
      setButtonRotate(!buttonRotate)
      if (isOpen === true) {
        setButtonRotate(true)
      } else {
        setButtonRotate(false)
      }
    }
    
  return (
    
      <div className={`bg-[#192A52] flex flex-row sm:flex-col justify-around items-center content-center w-[300px] max-w-[300px] flex-wrap rounded-lg shadow-lg gap-[10px] sm:pb-[10px] sm:px-[10px] py-[10px] ${isOpen ? 'rounded-lg pt-[10px] max-w-[300px] w-[300px]' : ''}`}>
        <img src={lock} alt=''/>

        <h4 className='font-bold text-white text-center'>Always safe</h4>
         <p className='text-[14px] mx-[10px] text-white text-center hidden sm:block'>Uploadswap does not make backups, your files will be encrypted and completely deleted after 7 days! Also E-mail addresses will not be saved</p> 
         
        <button href='' onClick={openBox}><img className={`sm:hidden ${buttonRotate ? "rotate-90" : "" }`} src={angle_right} alt=''/></button>
        {isOpen && <div>
        <p className='mt-[10px] pb-[10px] mx-[20px] text-white text-center sm:hidden'>Uploadswap does not make backups, your files will be encrypted and completely deleted after 7 days! Also E-mail addresses will not be saved</p>
                    </div>}  
          
      </div>
  );
}

export default Box2;


