import React, { useEffect, useState } from 'react'
import NameForm from '../../components/NameForm/NameForm'
import Logo from '../../components/Logo/Logo'
import Uploader from '../Uploader/Uploader'
import { useNavigate } from 'react-router-dom'
import warning from '../../images/warning.svg'
import three_dots from '../../images/three_dots.svg'

function Box1() {
    const [formData, setFormData] = useState({})
    const [files, setFiles] = useState([])
    const [isEnabled, setIsEnabled] = useState(true)
    const [filePath, setFilePath] = useState('')
    const [showError, setShowError] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    let navigate = useNavigate()
    const sendFiles = () => {
        let data = new FormData()
        data.append('email', formData.email)
        data.append('toEmails', formData.toEmails)

        if (formData.message) {
            data.append('message', formData.message)
        }

        if (formData.password) {
            data.append('password', formData.password)
        }

        files.forEach((file, index) => {
            data.append(`files_${index}`, file)
        })

        setIsLoading(true)
        fetch('https://api.uploadswap.com/upload/', {
            method: 'POST',
            body: data,
            headers: {
                accept: 'application/json'
            }
        })
            .then((response) => {
                response.json()
                setIsLoading(false)
            })
            .then(() => {
                navigate('/success', { state: { filePath: filePath } })
            })
            .catch(() => {
                setShowError(true)
            })
    }

    useEffect(() => {
        if (formData.email !== '' && formData.toEmails && formData.toEmails.length !== 0 && files.length !== 0) {
            if (formData.password !== '' && formData.check) {
                setIsEnabled(true)
            } else if (formData.password !== '' && !formData.check) {
                setIsEnabled(false)
            } else {
                setIsEnabled(true)
            }
        } else {
            setIsEnabled(false)
        }
    }, [formData, isEnabled, files])

    return (
        <>
            <div className='relative pb-[30px] bg-white rounded-lg shadow-lg flex items-center flex-col w-[300px] sm:w-[370px]'>
                <Logo />
                <Uploader
                    onChange={(files, path) => {
                        setFiles(files)
                        setFilePath(path)
                    }}
                />

                <NameForm
                    formToBox={(data) => {
                        setFormData(data)
                    }}
                />

                <button
                    onClick={sendFiles}
                    disabled={isEnabled ? false : true}
                    className={`${
                        isEnabled
                            ? 'self-center sm:self-end bg-[#1F366B] text-white rounded-md py-[7px] px-[25px] w-[260px] sm:w-[130px] text-center sm:mr-[25px]'
                            : 'self-center sm:self-end bg-[#ADABAB] text-white rounded-md py-[7px] px-[25px] w-[260px] sm:w-[130px] text-center sm:mr-[25px]'
                    }`}
                >
                    Send files
                </button>

                {isLoading && (
                    <div className='absolute w-full left-0 top-0 h-full bg-[#00000077] flex justify-center'>
                        <img src={three_dots} alt='Loading...' className='w-[100px]' />
                    </div>
                )}
            </div>
            {showError && (
                <div className='flex items-center w-[300px] gap-[10px] sm:w-[370px] h-[50px] bg-[#D44949] rounded-lg shadow-lg my-[20px]'>
                    <img src={warning} alt='error' className='w-[20px] ml-[20px]' />
                    <p className='text-white'>Something went wrong!</p>
                </div>
            )}
        </>
    )
}

export default Box1
