import React from 'react'
import Logo from '../Logo/Logo'
import Box2 from '../Box2/Box2'
import './DownloadPage.css'
import { Link, useLocation } from 'react-router-dom'

function Boxes() {
    const search = useLocation().search
    const name = new URLSearchParams(search).get('downurl')

    return (
        <div className='Boxes'>
            <div className='flex flex-col bg-white w-[300px] sm:w-[370px] pt-[20px] pb-[25px] px-[25px] justify-center gap-[10px] shadow-lg rounded-lg'>
                <Logo />
                <div className='pb-[10px]'>
                    <h1 className='font-semibold text-[20px] w-[250px] sm:w-[310px]'>Download file(s)</h1>
                    <p className='w-[250px] sm:w-[310px] text-[14px] text-[#00000099]'>
                        You have to enter a password to download the file.
                    </p>
                </div>

                <hr className='sm:w-[310px] w-[250px]'></hr>

                <div className='flex flex-row justify-between'>
                    <div className='flex flex-col flex-start'>
                        <p className='text-[14px] truncate max-w-[200px]'>#filename.jpeg </p>
                    </div>
                    <div className='flex flex-col pr-[10px]'>
                        <p className='text-[14px]'>#filesize MB</p>
                    </div>
                </div>

                <div className='pb-[10px]'>
                    <h2 className='font-semibold text-[16px] p-0 m-0'>Download link</h2>
                    <p className='w-[250px] sm:w-[310px] max-w-[150px] text-[14px] p-0 m-0 text-[#1F366B] underline break-words'>
                        {name}
                    </p>
                </div>
                <div className='pb-[10px]'>
                    <h2 className='font-semibold text-[16px]'>Message</h2>
                    <p className='w-[250px] sm:w-[310px]text-[14px]'>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. urna Quisque vitae nis lacus.
                    </p>
                </div>
                <button className='text-white sm:w-[310px] w-[260px] bg-[#1F366B] rounded-md py-[7px] mt-[10px] font-semibold mb-[5px]'>
                    Download file(s)
                </button>
                <Link to='/' className='text-[#1F366B] underline sm:text-left text-center'>
                    {' '}
                    Want to send more files?{' '}
                </Link>
            </div>

            <div>
                <Box2 />
            </div>
        </div>
    )
}

export default Boxes
