import './Uploader.css'
import plusbutton from '../../images/plusbutton.svg'
import React, { useState, useCallback, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import Trash from '../../images/Trash.svg'
import DeleteState from '../DeleteState/DeleteState'

function Uploader({ onChange }) {
    const [myFiles, setMyFiles] = useState([])
    const [fileToDelete, setFileToDelete] = useState(null)
    const [toggle, setToggle] = useState(false)
    const [fileName, setFileName] = useState('')

    const onDrop = useCallback(
        (acceptedFiles) => {
            setMyFiles([...myFiles, ...acceptedFiles])
        },
        [myFiles]
    )

    const { getRootProps, getInputProps } = useDropzone({
        onDrop
    })

    useEffect(() => {
        onChange(myFiles, fileName)
        setFileName(myFiles)
        //eslint-disable-next-line react-hooks/exhaustive-deps
    }, [myFiles, fileName])

    const deleteFile = () => {
        const newFiles = [
            ...myFiles.filter((file, index) => {
                return file.path !== fileToDelete.path
            })
        ]

        setMyFiles(newFiles)
        setToggle(false)
    }

    const removeAll = () => {
        setMyFiles([])
        setToggle(false)
    }

    const showDeleteModal = (file) => {
        setFileToDelete(file)
        setToggle(!toggle)
    }

    const handleSubmit = (e) => {
        e.preventDefault()
    }

    const files = myFiles.map((file, index) => (
        <div key={file.path}>
            <li className='p-y-[5px] flex justify-between my-[5px]'>
                <p className='text-sm grow max-w-[180px] overflow-hidden truncate justify-self-start'>{file.path}</p>
                <p className='text-sm'>{parseFloat((file.size * 100) / 100 / 1000000).toFixed(2)}&nbsp;MB</p>

                <button className='justify-self-end' onClick={() => showDeleteModal(file)}>
                    <img src={Trash} alt='' />
                </button>
            </li>
        </div>
    ))

    return (
        <form className='flex flex-col' onSubmit={handleSubmit}>
            <div
                {...getRootProps({
                    className:
                        'flex flex-row border-dashed border-2 items-center rounded-md w-[260px] max-w-[320px] sm:w-[320px] py-[10px] bg-[#FAFAFA] mb-[20px] hover:cursor-pointer'
                })}
            >
                <div className='px-2'>
                    <input {...getInputProps()} />
                    <img src={plusbutton} alt='' />
                </div>
                <p className='text-md font-semibold pl-1'>
                    Drop your files here <br />
                    <span className='text-sm font-normal'>or click to select</span>
                </p>
            </div>

            <ul>{files}</ul>

            {files.length > 0 && (
                <button
                    className='py-[5px] mb-[5px] self-start text-[#D44949] underline font-semibold text-sm'
                    onClick={removeAll}
                >
                    Remove all
                </button>
            )}
            {toggle && (
                <DeleteState onCancel={() => setToggle(false)} onDelete={deleteFile} fileName={fileToDelete.path} />
            )}
        </form>
    )
}

export default Uploader
